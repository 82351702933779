import '../helpers/helpers';

import * as slickSliders from '../functions/slickSliders';
// import * as googleMap from '../functions/googleMap';
import * as scrollEvent from '../eventHandlers/scrollEvent';
import {bindEvent} from '../eventHandlers/eventHandler';
import * as menuShow from '../functions/menuShow';
import * as christmasShow from '../functions/christmasShow';
import * as mobileNav from '../functions/mobileNav';

import * as skrollr from 'skrollr';

document.addEventListener('DOMContentLoaded', function(){

	var $mobileFixedNav = document.querySelectorAll('.mobile-fixed-nav'),
		$mCustomScrollbar = document.querySelectorAll('.mCustomScrollbar'),
		$scrollDown = $('.scroll-down'),
		$mainSlider = document.querySelectorAll('.main-slider');

	if(!$('html').hasClass('tablet-view') && !$('html').hasClass('mobile-view')){
		var mySkrollr = skrollr.init({
			edgeStrategy: 'set',
			smoothScrolling: false,
			forceHeight: false
		});
	}

	$(".lang-hld a").removeAttr("title");

	scrollEvent.init();

	slickSliders.init();

	// googleMap.init();

	document.querySelector('.btn--map').addEventListener('click', function(){
		document.querySelector('.fixed-container-hld').classList.add('js-show');
		document.querySelector('.fixed-container-hld').classList.remove('fixed-container-hld--zomato');

		$('#map').removeClass('js-hide');
	});

	$('.close-btn--map, .container-blank').on('click', function(){
		document.querySelector('.fixed-container-hld').classList.remove('js-show');

		$('#zomato').removeClass('js-show');
	});

	if($mobileFixedNav) {
		mobileNav.init();
	}

	$('.menu a, #logo, .goto').on('click', function(e){
		if(!$(this).parent().hasClass('outside-link')){
			e.preventDefault();

			var $scrollTo = $(this).attr('href');

			$('html, body').animate({
		        scrollTop: $($scrollTo).offset().top
		    }, 500);
		}

		if($('body').hasClass('mobile-nav-active')){
			$('body').removeClass('mobile-nav-active');
		}
	});

	$('.goto').on('click', function(){
		$('.popup-hld--startpage').removeClass('js-show');
	});

	if($mCustomScrollbar) {
		menuShow.init();
		// christmasShow.init();

		var menuElementsHeight = $('.menu-elements').height();

		$('.mCustomScrollbar').mCustomScrollbar({
			setHeight: menuElementsHeight,
			mouseWheel:{ 
				scrollAmount: 400,
				preventDefault: true
			},
			advanced:{ 
				updateOnContentResize: true
			}
		});
	}

	if($scrollDown) {
		$scrollDown.on('click', function(){
			$("html, body").animate({ scrollTop: $scrollDown.offset().top+150 }, 850);
		});
	}

	if($('.popup-hld--startpage')) {
		$('.popup-hld--startpage').addClass('js-show');
	}

	$('.close-btn, .popup-blank').on('click', function(){
		$('.popup-hld').removeClass('js-show');
	});

	// $('.zomato-book').on('click', function(){
	// 	document.querySelector('.fixed-container-hld').classList.add('js-show');
	// 	document.querySelector('.fixed-container-hld').classList.add('fixed-container-hld--zomato');

	// 	if($(window).width() < 1023) {
	// 		$('.js-mobile-trigger').trigger('click');
	// 	}

	// 	$('#map').addClass('js-hide');
	// 	$('#zomato').addClass('js-show');
	// });

});

AOS.init({
	disable: 'mobile',
	easing: 'ease-in-out-sine',
	duration: 800
});