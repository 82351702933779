import {bindEvent} from '../eventHandlers/eventHandler';
var _throttle = require('lodash.throttle');

export function init() {
	var $sidebar = document.getElementById('sidebar'),
		scrolledFromTop = 50,
		blankVar = true;

	function scrollInit() {
		var scrollTop = $(this).scrollTop();

		$('.menu a').each(function() {
			if(!$(this).parent().hasClass('outside-link')){
		        var currLink = $(this);
		        var refElement = $(currLink.attr("href"));

		        // if(refElement.offset().top <= scrollTop && refElement.offset().top + refElement.height() > scrollTop) {
		        //     currLink.addClass("active");
		        // }
		        // else {
		        //     currLink.removeClass("active");
		        // }
			}
	    });

		if(scrollTop > scrolledFromTop && !$sidebar.hasClass('js-slide-down')) {

			$sidebar.classList.add('js-slide-down');

		} else if($sidebar.hasClass('js-slide-down') && scrollTop <= scrolledFromTop) {

			$sidebar.classList.remove('js-slide-down');

		}

	}

	function initSlideronScroll() {
		var scrollTop = $(this).scrollTop(),
			$sections = $('.section');

		if(blankVar === true){
			$sections.each(function(){
				var top = $(this).offset().top - 200,
					bottom = top + $(this).outerHeight();

				if(scrollTop >= top && scrollTop <= bottom){
						if($(this).is('#gallery')){
							$('.gallery').slick('slickSetOption','autoplay', true, true).slick('slickPlay');

							setTimeout(function(){
								$('.gallery').slick('slickSetOption','autoplaySpeed', 2000, true);
							}, 0);

							blankVar = false
						}
				}
			});
		}
	}

	if($(window).width() > 1024) {
		bindEvent(window, 'load', scrollInit);
		bindEvent(window, 'scroll', _throttle(scrollInit, 500));
	}

	bindEvent(window, 'load', initSlideronScroll);
	bindEvent(window, 'scroll', _throttle(initSlideronScroll, 500));
};