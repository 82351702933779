import {bindEvent} from '../eventHandlers/eventHandler';

export function init() {

	var $gallery = document.getElementById('gallery'),
		$menuSlider = document.getElementById('image-menu-slider'),
		$christmasSlider = document.getElementById('image-christmas-slider'),
		$imageContentSlider = document.getElementById('image-content-slider'),
		$imageNewsSlider = document.getElementById('image-news-slider'),
		$imageInfoSlider = document.getElementById('image-info-slider'),
		$imageNewYearSlider = document.getElementById('image-newyear-slider');

	if($gallery) {
		$('.gallery').on('init', function(event, slick) {
			var $activeSlide = $('.gallery .slick-track .slick-active');
			var $activeSlideDataAttr = $activeSlide.find('img').attr("data-lazy-load");

			if(typeof $activeSlideDataAttr !== typeof undefined && $activeSlideDataAttr !== false) {
				$activeSlide.css('background-image', 'url('+$activeSlideDataAttr+')');
				$activeSlide.find('img').attr('src', $activeSlideDataAttr);
				$activeSlide.find('img').removeAttr('data-lazy-load');
			}
			
			if($activeSlide.next().length) {
				var $nextSlideDataAttr = $activeSlide.next().find('img').attr("data-lazy-load");

				if(typeof $nextSlideDataAttr !== typeof undefined && $nextSlideDataAttr !== false) {
					$activeSlide.next().css('background-image', 'url('+$nextSlideDataAttr+')');
					$activeSlide.next().find('img').attr('src', $nextSlideDataAttr);
					$activeSlide.next().find('img').removeAttr('data-lazy-load');
				}
			}

			if($(window).width() <= 1024) {
				$('.gallery-arrows .slick-next').on('touchstart', function(e){
					e.preventDefault();

					$('.gallery').slick('slickNext');
				});

				$('.gallery-arrows .slick-prev').on('touchstart', function(e){
					e.preventDefault();

					$('.gallery').slick('slickPrev');
				});
			}

		});


		$('.gallery').on('afterChange', function(event, slick, currentSlide, nextSlide){
			var $activeSlide = $('.gallery .slick-track .slick-active');

			if($activeSlide.next().length) {
				var $nextSlideDataAttr = $activeSlide.next().find('img').attr("data-lazy-load");

				if(typeof $nextSlideDataAttr !== typeof undefined && $nextSlideDataAttr !== false) {
					$activeSlide.next().css('background-image', 'url('+$nextSlideDataAttr+')');
					$activeSlide.next().find('img').attr('src', $nextSlideDataAttr);
					$activeSlide.next().find('img').removeAttr('data-lazy-load');
				}
			}
		});

		$('.gallery').slick({
			infinite: false,
			speed: 1000,
			slidesToShow: 1,
			autoplay: false,
			useTransform: true,
			pauseOnHover: false,
			pauseOnFocus: false,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: true,
			prevArrow: '<span class="slick-prev icon-left-arrow"></span>',
			nextArrow: '<span class="slick-next icon-right-arrow"></span>',
			appendArrows: document.querySelector('.gallery-arrows')
		});
	}

	if($imageNewsSlider) {
		$('#image-news-slider').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			fade: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: false,
			lazyLoad: 'ondemand',
			asNavFor: '.news-slider .content-slider-inner'
		});


		$('.news-slider .content-slider-inner').on('init', function(event, slick){
			var contentSliderNewsItem = $('.content-slider-item').outerWidth();
			
			$('.news-slider .content-slider-arrows').on('click', '.slick-next', function(){
				$(this).css('transform',  "translateX(" + 0+ "px)");
				$('.news-slider .content-slider-arrows .slick-prev').css('transform',  "translateX(" + -contentSliderNewsItem+ "px)");
			});

			$('.news-slider .content-slider-arrows').on('click', '.slick-prev', function(){
				$('.news-slider .content-slider-arrows .slick-next').css('transform',  "translateX(" + contentSliderNewsItem*2+ "px)");
				$(this).css('transform',  "translateX(" + contentSliderNewsItem+ "px)");
			});

			$('body').on('click', '.slick-prev.prev', function(){
				$('#image-news-slider').slick('slickPrev')
				$('.news-slider .content-slider-inner').slick('slickPrev')
			});

			$('body').on('click', '.slick-next.next', function(){
				$('#image-content-slider').slick('slickNext');
				$('.news-slider .content-slider-inner').slick('slickNext');
			});

			var imageNewsSlidesCount = $('#image-news-slider').find('.image').length;
			$('.news-slider').attr('data-img-num', imageNewsSlidesCount);
		});

		$('.news-slider .content-slider-inner').on('afterChange', function(event, slick, currentSlide, nextSlide){
			$('.news-slider .content-slider-arrows').empty().append('<span class="slick-prev slick-arrow prev" style="display: block;"></span> <span class="slick-next slick-arrow next" style="display: block; transform: translateX(800px);"></span>')
		});

		$('.news-slider .content-slider-inner').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			useTransform: true,
			variableWidth: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: true,
			focusOnSelect: true,
			lazyLoad: 'ondemand',
			asNavFor: '#image-news-slider',
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>',
			appendArrows: document.querySelector('.news-slider .content-slider-arrows')
		});
	}

	if($imageNewYearSlider) {
		$('#image-newyear-slider').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			fade: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: false,
			lazyLoad: 'ondemand',
			asNavFor: '.newyear-slider .content-slider-inner'
		});

		$('.newyear-slider .content-slider-inner').on('init', function(event, slick){
			var contentSliderNewYearItem = $('.content-slider-item').outerWidth();
			
			$('.newyear-slider .content-slider-arrows').on('click', '.slick-next', function(){
				$(this).css('transform',  "translateX(" + 0+ "px)");
				$('.newyear-slider .content-slider-arrows .slick-prev').css('transform',  "translateX(" + -contentSliderNewYearItem+ "px)");
			});

			$('.newyear-slider .content-slider-arrows').on('click', '.slick-prev', function(){
				$('.newyear-slider .content-slider-arrows .slick-next').css('transform',  "translateX(" + contentSliderNewYearItem*2+ "px)");
				$(this).css('transform',  "translateX(" + contentSliderNewYearItem+ "px)");
			});

			$('body').on('click', '.slick-prev.prev', function(){
				$('#image-newyear-slider').slick('slickPrev')
				$('.newyear-slider .content-slider-inner').slick('slickPrev')
			});

			$('body').on('click', '.slick-next.next', function(){
				$('#image-newyear-slider').slick('slickNext');
				$('.newyear-slider .content-slider-inner').slick('slickNext');
			});

			var imageNewYearSlidesCount = $('#image-newyear-slider').find('.image').length;
			$('.newyear-slider').attr('data-img-num', imageNewYearSlidesCount);
		});

		$('.newyear-slider .content-slider-inner').on('afterChange', function(event, slick, currentSlide, nextSlide){
			$('.newyear-slider .content-slider-arrows').empty().append('<span class="slick-prev slick-arrow prev" style="display: block;"></span> <span class="slick-next slick-arrow next" style="display: block; transform: translateX(800px);"></span>')
		});

		$('.newyear-slider .content-slider-inner').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			useTransform: true,
			variableWidth: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: true,
			focusOnSelect: true,
			lazyLoad: 'ondemand',
			asNavFor: '#image-newyear-slider',
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>',
			appendArrows: document.querySelector('.newyear-slider .content-slider-arrows')
		});
	}

	if($imageInfoSlider) {
		$('#image-info-slider').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			fade: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: false,
			lazyLoad: 'ondemand',
			asNavFor: '.info-slider .content-slider-inner'
		});

		$('.info-slider .content-slider-inner').on('init', function(event, slick){
			var contentSliderInfoItem = $('.content-slider-item').outerWidth();
			
			$('.info-slider .content-slider-arrows').on('click', '.slick-next', function(){
				$(this).css('transform',  "translateX(" + 0+ "px)");
				$('.info-slider .content-slider-arrows .slick-prev').css('transform',  "translateX(" + -contentSliderInfoItem+ "px)");
			});

			$('.info-slider .content-slider-arrows').on('click', '.slick-prev', function(){
				$('.info-slider .content-slider-arrows .slick-next').css('transform',  "translateX(" + contentSliderInfoItem*2+ "px)");
				$(this).css('transform',  "translateX(" + contentSliderInfoItem+ "px)");
			});

			$('body').on('click', '.slick-prev.prev', function(){
				$('#image-info-slider').slick('slickPrev')
				$('.info-slider .content-slider-inner').slick('slickPrev')
			});

			$('body').on('click', '.slick-next.next', function(){
				$('#image-content-slider').slick('slickNext');
				$('.info-slider .content-slider-inner').slick('slickNext');
			});

			var imageInfoSlidesCount = $('#image-info-slider').find('.image').length;
			$('.info-slider').attr('data-img-num', imageInfoSlidesCount);
		});

		$('.info-slider .content-slider-inner').on('afterChange', function(event, slick, currentSlide, nextSlide){
			$('.info-slider .content-slider-arrows').empty().append('<span class="slick-prev slick-arrow prev" style="display: block;"></span> <span class="slick-next slick-arrow next" style="display: block; transform: translateX(800px);"></span>')
		});

		$('.info-slider .content-slider-inner').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			useTransform: true,
			variableWidth: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: true,
			focusOnSelect: true,
			lazyLoad: 'ondemand',
			asNavFor: '#image-info-slider',
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>',
			appendArrows: document.querySelector('.info-slider .content-slider-arrows')
		});
	}

	if($imageContentSlider) {
		$('#image-content-slider').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			fade: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: false,
			lazyLoad: 'ondemand',
			asNavFor: '.content-slider-restaurant .content-slider-inner'
		});

		$('.content-slider-restaurant .content-slider-inner').on('init', function(event, slick){
			var contentSliderItemWidth = $('.content-slider-item').outerWidth();
			
			$('.content-slider-restaurant .content-slider-arrows').on('click', '.slick-next', function(){
				$(this).css('transform',  "translateX(" + 0+ "px)");
				$('.content-slider-restaurant .content-slider-arrows .slick-prev').css('transform',  "translateX(" + -contentSliderItemWidth+ "px)");
			});

			$('.content-slider-restaurant .content-slider-arrows').on('click', '.slick-prev', function(){
				$('.content-slider-restaurant .content-slider-arrows .slick-next').css('transform',  "translateX(" + contentSliderItemWidth*2+ "px)");
				$(this).css('transform',  "translateX(" + contentSliderItemWidth+ "px)");
			});

			$('body').on('click', '.slick-prev.prev', function(){
				$('#image-content-slider').slick('slickPrev')
				$('.content-slider-inner').slick('slickPrev')
			});

			$('body').on('click', '.slick-next.next', function(){
				$('#image-content-slider').slick('slickNext');
				$('.content-slider-inner').slick('slickNext');
			});

			var imageSlidesCount = $('#image-content-slider').find('.image').length;
			$('.content-slider-restaurant').attr('data-img-num', imageSlidesCount);
		});
		
		$('.content-slider-inner').on('afterChange', function(event, slick, currentSlide, nextSlide){
			$('.content-slider-restaurant .content-slider-arrows').empty().append('<span class="slick-prev slick-arrow prev" style="display: block;"></span> <span class="slick-next slick-arrow next" style="display: block; transform: translateX(800px);"></span>')
		});

		$('.content-slider-restaurant .content-slider-inner').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			useTransform: true,
			variableWidth: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: true,
			focusOnSelect: true,
			lazyLoad: 'ondemand',
			asNavFor: '#image-content-slider',
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>',
			appendArrows: document.querySelector('.content-slider-restaurant .content-slider-arrows')
		});

	}

	if($menuSlider) {
		$('#image-menu-slider').slick({
			infinite: false,
			speed: 1000,
			slidesToShow: 1,
			fade: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: false,
			lazyLoad: 'ondemand'
		});
	}

	if($christmasSlider) {
		$('#image-christmas-slider').slick({
			infinite: false,
			speed: 1000,
			slidesToShow: 1,
			fade: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: false,
			lazyLoad: 'ondemand'
		});
	}

}