import {bindEvent} from '../eventHandlers/eventHandler';

export function init(){
	var $menuCategory = $('.menu-category-list'),
		$dishedLists = $('.dishes-list'),
		$menuElements = $('#menu-elements'),
		$imageMenuSlider = $('#image-menu-slider'),
		$closeMenuBtn = document.querySelector('.close-btn--menu');


	$imageMenuSlider.slick('slickGoTo', 1);

	$menuCategory.on('click', 'li', function(){
		var $self = $(this),
			menuCategoryData = $self.attr('data-category-num');

		$('.menu-category-list').find('li').removeClass('active');

		$imageMenuSlider.slick('slickGoTo', parseInt(menuCategoryData));

		$self.addClass('active');
		// $('.pdf-link').removeClass('active');

		// if(menuCategoryData == 1) {
		// 	$('.pdf-link-lunch').addClass('active');
		// 	$('.pdf-link-dessert').addClass('active');
		// 	$('.pdf-link-wine').addClass('active');
		// } else if(menuCategoryData == 2) {
		// 	$('.pdf-link-dinner').addClass('active');
		// 	$('.pdf-link-dessert2').addClass('active');
		// 	$('.pdf-link-wine').addClass('active');
		// } else if(menuCategoryData == 3) {
		// 	$('.pdf-link-wine').addClass('active');
		// }

		// $('.dishes-list:visible').fadeOut();
		
		// $('[data-list-num="'+menuCategoryData+'"]').fadeIn(function(){
		// 	$($menuElements).addClass('js-slide-right');
		// });

		// console.log('[data-list-num="'+menuCategoryData+'"]');

		if(!$menuElements.hasClass('js-slide-right')){
			// if(menuCategoryData != 3) {
				$('.dishes-list:visible').fadeOut();

				$('[data-list-num="'+menuCategoryData+'"]').fadeIn(function(){
					$($menuElements).addClass('js-slide-right');
				});
			// } 
		} else {
			// if(menuCategoryData != 3) {
				$('.dishes-list:visible').fadeOut();
				$('.mCustomScrollbar').mCustomScrollbar("scrollTo",'top',{
				    scrollInertia:0
				});
				$('[data-list-num="'+menuCategoryData+'"]').fadeIn();
			// }
		}
	});

	$dishedLists.eq(0).fadeIn();

	bindEvent($closeMenuBtn, 'click', function(){
		$menuElements.removeClass('js-slide-right');
	});
}